import { Button, Card, CardContent, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

const DynamicOptionsWidget = (props) => {
    let references = props.payload.references;
    return (

        <Carousel next={(next, active) => console.log(`we left ${active}, and are now at ${next}`)}
            prev={(prev, active) => console.log(`we left ${active}, and are now at ${prev}`)}
            autoPlay={false}
            swipe={true}
            navButtonsAlwaysVisible={true}
        >
            {
                references.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel >

    );
};

function Item(props) {
    return (
        <Paper style={{ width: '70%', marginLeft: '15%' }}>
            <Grid direction="column">
                <div>{props.item.name}</div>
                <div>
                    <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{props.item.desc}</Typography>
                    <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{props.item.price}</Typography>
                </div>
                <div style={{textAlignLast:'center'}}>
                    <Button>Add</Button>
                </div>
            </Grid>
        </Paper>
    )
}
/*
           <Stack direction="row" spacing={3} style={{overflowX: "scroll"}}>
           {references.map((card, index) => (
               <Grid size={{ xs: 4, sm: 8, lg: 8 }}>
                   <Card onClick={() => { alert('clicked', card.name) }}>
                       <CardContent>
                           <Stack direction="column">
                               <div>{card.name}</div>
                               <div>
                                   <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{card.desc}</Typography>
                                   <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{card.price}</Typography>
                                   <Button>Add</Button>
                               </div>
                           </Stack>
                       </CardContent>
                   </Card>
               </Grid>
           ))}
           </Stack>
       */

export default DynamicOptionsWidget;