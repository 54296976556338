import React from "react";

const MessageParser = ({ children, actions }) => {

  const parse = async (message) => {
    console.log("parse>>>>>> ",message);
      const lowercase = message.toLowerCase();
  
      if (lowercase.includes("hello") || message === `Hey I'm resolving an incident`) {
        actions.greet();
      } else if (lowercase.includes("resolution needed")) {
        console.log("parsing invoked");
      } else {
        //flow to setch service catalog
        //actions.callServiceCatalogAPI(lowercase)
        actions.callChatBotAPI(lowercase)
      }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions
        });
      })}
    </div>
  );
};
  
export default MessageParser;
  