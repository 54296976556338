import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import ForgotPassword from './ForgotPassword';
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';
import axios from 'axios';
import { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from '@mui/lab';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

export default function SignInCard() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const validateInputs = async () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');

    /*
    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
    */
    try {
      setLoading(true);
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

      const response = await axios.post('https://api.kogniv.com/api/v1/auth/token', {
        "username": email.value,
        "password": password.value
      });
      console.log('Login successful:', response);
      if (response.status === 200) {
        axios.defaults.headers.get['Authorization'] = `Bearer ${response.data.access_token}`;
        axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.get['Content-Type'] = 'application/json';
        let res = await axios.get(`https://api.kogniv.com/api/v1/auth/users/me`);
        setLoading(false);
        if (res.status === 200) {
          console.log("user::: ", res.data);

          axios.defaults.headers.post['x-api-key'] = `test`;
          axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
          axios.defaults.headers.post['Content-Type'] = 'application/json';
          const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
          let data = {
            "appID": 1,
            "params": {
              "data": null,
              "entity": "sys_user",
              "methodType": "get",
              "query": {
                "sysparm_query": {
                  "email": email.value
                },
                "sysparm_fields": "user_name,roles,sys_id",
                "sysparm_display_value": "true"
              }
            }
          }
          let res1 = await axios.post(`${hostName}`, data);
          if (res1.status === 200) {
            console.log("snow user:::> ", res1)
          }
          
          
          data = {
            "appID": 1,
            "params": {
              "data": null,
              "entity": "use_assets",
              "methodType": "get",
              "query": {
                "sysparm_query": {
                  "assigned_to": res1.data.data.result[0].sys_id
                },
                "sysparm_fields": "display_name,model_category,model,sys_id,install_date,asset_tag,depreciated_amount,cost,quantity,ci,serial_number,location",
                "sysparm_display_value": "true"
              }
            }
          }
          let res2 = await axios.post(`${hostName}`, data);
          if (res2.status === 200) {
            console.log("user asset:::> ", res2)
          }

          data = {
            "appID": 1,
            "params": {
              "data": null,
              "entity": "users_groups",
              "methodType": "get",
              "query": {
                "sysparm_query": {
                  "user": res1.data.data.result[0].sys_id
                }
              }
            }
          }
          let res3 = await axios.post(`${hostName}`, data);
          if (res3.status === 200) {
            console.log("snow user:::> ", res3)
          }
          
          await login(response.data, email.value, res.data, res1.data.data.result, res2.data.data.result, res3.data.data.result);
        }
      } else {
        alert("Invalid username or password");
      }
    } catch (error) {
      setLoading(false);
      if (error.status === 401) {
        alert("Invalid username or password");
        console.error('Login error:', error);
      } else {
        alert("Error, try after sometime");
        console.error('Login error:', error);
      }

    }
  };

  return (
    <Card variant="outlined">
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <SitemarkIcon />
      </Box>
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            error={emailError}
            helperText={emailErrorMessage}
            id="email"
            type="email"
            name="email"
            placeholder="your@email.com"
            autoComplete="email"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? 'error' : 'primary'}
            sx={{ ariaLabel: 'email' }}
          />
        </FormControl>
        <FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Link
              onClick={handleClickOpen}
              variant="body2"
              sx={{ alignSelf: 'baseline' }}
            >
              Forgot your password?
            </Link>
          </Box>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={passwordError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <ForgotPassword open={open} handleClose={handleClose} />
        <LoadingButton sx={{ color: 'white !important' }}
          type="submit" fullWidth variant="contained" onClick={validateInputs}
          loading={loading}
          loadingPosition="start"
        >Sign In</LoadingButton>
        <Typography sx={{ textAlign: 'center' }}>
          Don&apos;t have an account?{' '}
          <span>
            <Link
              href="/sign-up"
              variant="body2"
              sx={{ alignSelf: 'center' }}
            >
              Sign up
            </Link>
          </span>
        </Typography>
      </Box>
    </Card>
  );
}
