import * as React from 'react';
import { useEffect, useState } from "react";
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../internals/components/Copyright';
import axios from "axios";
import { Avatar, CardHeader, Chip, Divider, Icon, IconButton, List, ListItemAvatar } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import StatCard from './StatCard';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { AccessTimeOutlined, ArrowForwardIosOutlined, Assignment, AssignmentOutlined, PeopleAltOutlined, SupportAgentOutlined, TaskAltOutlined, TaskOutlined, ThumbDownOffAltOutlined, ThumbUpOffAltOutlined } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'
const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function MainGrid() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const [tableLoading, setTableLoading] = useState(false);
  const [srList, setSrList] = useState([
    {
      "title": "Incidents",
      "data_active": 0,
      "data_assigned": 0,
      "data_grp_assigned": 0,
      "data_closed": 0,
      "link": "/my-incidents",
      "active": true
    },
    {
      "title": "Service Requests",
      "data_active": 0,
      "data_assigned": 0,
      "data_grp_assigned": 0,
      "data_closed": 0,
      "link": "/my-sr",
      "active": true
    },
    {
      "title": "HR Cases",
      "data_active": 0,
      "data_assigned": 0,
      "data_grp_assigned": 0,
      "data_closed": 0,
      "link": "/my-cases",
      "active": true
    },
    {
      "title": "My Approvals",
      "data_active": 0,
      "data_assigned": 0,
      "data_grp_assigned": 0,
      "data_closed": 0,
      "link": "/my-approvals"
    },
    {
      "title": "Tasks",
      "data_active": 0,
      "data_assigned": 0,
      "data_grp_assigned": 0,
      "data_closed": 0,
      "link": "/my-tasks",
      "active": true
    },
    {
      "title": "Change Requests",
      "data_active": 0,
      "data_assigned": 0,
      "data_grp_assigned": 0,
      "data_closed": 0,
      "link": "",
      "active": true
    }
  ]);
  let userData = [];
  const handleClick = (link) => {
    if (link.includes('incident')) {
      navigate(link);
    }
    
  }
  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length - 1);

  let userID = localStorage.getItem("userID").toLocaleLowerCase();
  userID = userID.substring(1, userID.length - 1);
  const navigate = useNavigate();
  //userRole = 'itil'
  let announcements = [
    {
      title: "New Leave Policy",
      content: "Leave policy for the FY 2024-2025 hs been updated here...",
      link: ""
    },
    {
      title: "New Leave Policy",
      content: "Leave policy for the FY 2024-2025 hs been updated here...",
      link: ""
    },
    {
      title: "New Leave Policy",
      content: "Leave policy for the FY 2024-2025 hs been updated here...",
      link: ""
    }
  ]

  let meetings = [
    {
      title: "Daily scrum",
      time: "9.00 AM - 9.30 AM",
      app: "Teams",
      link: "",
      type: "meeting"
    },
    {
      title: "Book conf room",
      time: "11.00 AM",
      link: "",
      app: "Mark Complete",
      type: "reminder"
    },
    {
      title: "Do the ethics training",
      time: "11.30 AM",
      app: "Mark Complete",
      link: "",
      type: "reminder"
    },
    {
      title: "Townhall",
      time: "12.30 PM - 1.30 PM",
      app: "zoom",
      link: "",
      type: "meeting"
    },
    {
      title: "WSR Meeting",
      time: "4.00 PM - 4.30 PM",
      app: "zoom",
      link: "",
      type: "meeting"
    }
  ]

  let career = [
    {
      title: "Quick learning on LLMs",
      app: "Learn here",
      link: "",
      type: "learn"
    },
    {
      title: "Have a quick discussion with your counserllor",
      app: "Chat now",
      link: "",
      type: "task"
    },
    {
      title: "Think of taking free AI Certification",
      app: "Check here",
      link: "",
      type: "learn"
    }
  ]

  let event = [
    {
      title: "ACE Awards 2024",
      content: "ACE Awards of year 2024 is going to be on 28th October 2024 at...",
      link: ""
    },
    {
      title: "ACE Awards 2024",
      content: "ACE Awards of year 2024 is going to be on 28th October 2024 at...",
      link: ""
    },
    {
      title: "ACE Awards 2024",
      content: "ACE Awards of year 2024 is going to be on 28th October 2024 at...",
      link: ""
    }
  ]

  if (userRole === 'itil' || userRole === "super_admin") {
    userData = [
      {
        "title": "Incidents",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-incidents",
        "active": true
      },
      {
        "title": "Service Requests",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-sr",
        "active": true
      },
      {
        "title": "HR Cases",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-cases",
        "active": true
      },
      {
        "title": "My Approvals",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-approvals"
      },
      {
        "title": "Tasks",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-tasks",
        "active": true
      },
      {
        "title": "Change Requests",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "",
        "active": true
      }
    ]
  } else if (userRole === 'approver') {
    userData = [
      {
        "title": "My Incidents",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-incidents"
      },
      {
        "title": "My Service Requests",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-sr"
      },
      {
        "title": "My HR Cases",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-cases"
      },
      {
        "title": "My Approvals",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-approvals"
      }
    ]
  } else if (userRole === 'employee') {
    userData = [
      {
        "title": "My Incidents",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-incidents"
      },
      {
        "title": "My Service Requests",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-sr"
      },
      {
        "title": "My HR Cases",
        "data_active": 0,
        "data_assigned": 0,
        "data_grp_assigned": 0,
        "data_closed": 0,
        "link": "/my-cases"
      }
    ]
  }
  const getSRList = async () => {
    try {
      setTableLoading(true);
      let user = localStorage.getItem("user").toLocaleLowerCase();
      user = user.substring(1, user.length - 1)
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';

      //my items data
      let data1 = {
        "appID": 1,
        "params": {
          "data": null,
          "entity": "incident",
          "methodType": "get",
          "query": {
            "sysparm_query": {
              "caller_id": userID,
              "active":true
            },
            "sysparm_fields": "number"
          }
        }
      }
      let res = await axios.post(`${hostName}`, data1);
      if (res.status === 200) {
        userData[0].data_active = res.data.data.result.length;
      } else {
        console.log(res.status);
      }

      console.log("incidents over>>>", userData);
      data1 = {
        "appID": 1,
        "params": {
          "data": null,
          "entity": "list_sr",
          "methodType": "get",
          "query": {
            "sysparm_query": {
              "requested_for": userID
            },
            "sysparm_fields": "number"
          }
        }
      }
      res = await axios.post(`${hostName}`, data1);
      if (res.status === 200) {
        userData[1].data_active = res.data.data.result.length;
      } else {
        console.log(res.status);
      }

      data1 = {
        "appID": 1,
        "params": {
          "data": null,
          "entity": "list-hr-cases",
          "methodType": "get",
          "query": {
            "sysparm_query": {
              "opened_for": userID
            },
            "sysparm_fields": "number"
          }
        }
      }
      res = await axios.post(`${hostName}`, data1);
      if (res.status === 200) {
        userData[2].data_active = res.data.data.result.length;
      } else {
        console.log(res.status);
      }

      if (userRole === 'itil' || userRole === 'super_admin') {
        let data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "getsctasks",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "opened_by": userID
              },
              "sysparm_fields": "number"
            }
          }
        }
        let res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[4].data_active = res.data.data.result.length;

        } else {
          console.log(res.status);

        }
        let assignmentGrpObj = localStorage.getItem("snowUserGroups")
        assignmentGrpObj = JSON.parse(assignmentGrpObj);
        assignmentGrpObj = JSON.parse(assignmentGrpObj)
        let assignmentGrp = ''
        for (const [i, grp] of assignmentGrpObj.entries()) {
          if (i === 0) {
            assignmentGrp = 'assignment_group=' + grp.group.value;
          } else {
            assignmentGrp = assignmentGrp + '^ORassignment_group=' + grp.group.value;
          }
        }

        // incidents, cases, SR, tasks assigned to each individual and their assignment group
        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "incident",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "assigned_to": userID,
                "active":true
              },
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[0].data_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "incident",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "search": assignmentGrp,
                "active":true
              },
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[0].data_grp_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "list_sr",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "assigned_to": userID
              },
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[1].data_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "list_sr",
            "methodType": "get",
            "query": {
              "sysparm_query": assignmentGrp,
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[1].data_grp_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "list-hr-cases",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "assigned_to": userID
              },
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[2].data_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "list-hr-cases",
            "methodType": "get",
            "query": {
              "sysparm_query": assignmentGrp,
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[2].data_grp_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "getsctasks",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "assigned_to": userID
              },
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[4].data_assigned = res.data.data.result.length;

        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "getsctasks",
            "methodType": "get",
            "query": {
              "sysparm_query": assignmentGrp,
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[4].data_grp_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "change_request",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "assigned_to": userID
              },
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[5].data_assigned = res.data.data.result.length;

        } else {
          console.log(res.status);
        }

        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "change_request",
            "methodType": "get",
            "query": {
              "sysparm_query": assignmentGrp,
              "sysparm_fields": "number"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[5].data_grp_assigned = res.data.data.result.length;
        } else {
          console.log(res.status);
        }
      }

      if (userRole === 'approver' || userRole === 'itil' || userRole === 'super_admin') {
        data1 = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "sysapprover_update",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "approver": userID,
                "state": "requested"
              },
              "sysparm_fields": "sys_id"
            }
          }
        }
        res = await axios.post(`${hostName}`, data1);
        if (res.status === 200) {
          userData[3].data_active = res.data.data.result.length;;

        } else {
          console.log(res.status);

        }
      }
    }
    catch (error) {
      console.log("error while fetching inc list:::", error)
    }
    setTableLoading(false);
    setSrList(userData);
  };
  useEffect(() => {
    getSRList()
  }, []);


  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Stack direction="row" spacing={3}>
        {(userRole === 'employee' || userRole === 'approver') && <Grid size={8} spacing={2}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            What's in for today?
          </Typography>
          <Grid
            container
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}>
            {meetings.map((card, index) => (
              <Grid size={{ xs: 10, sm: 8, lg: 4 }}>
                <Card >
                  <CardContent>
                    <Stack direction="row">
                      <div>{card.type === 'meeting' && <EventIcon />}
                        {card.type === 'reminder' && <TaskAltOutlined />}</div>
                      <div>
                        <p style={{ margin: '0em 0em 0em 0.2em' }}>{card.time}</p>
                        <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{card.title}</Typography>
                        <Chip label={card.app} color="primary" variant="outlined" />
                      </div>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>}
        {(userRole === 'itil' || userRole === 'super_admin') && <Grid size={8} spacing={2}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            My Assignments
          </Typography>
          <Grid
            container
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}>
            {srList.map((card, index) => (
              <Grid size={{ xs: 10, sm: 8, lg: 4 }}>
                <Card onClick={() => handleClick(card.link+"?filter=assigned_inc")}>
                  <CardContent>
                    <Stack direction="row">
                      <div>{card.data_assigned}</div>
                      <div>
                        <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{card.title}</Typography>
                      </div>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            My Group's Assignments
          </Typography>
          <Grid
            container
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}>
            {srList.map((card, index) => (
              <Grid size={{ xs: 10, sm: 8, lg: 4 }}>
                <Card onClick={() => handleClick(card.link+"?filter=grp_assigned_inc")}>
                  <CardContent>
                    <Stack direction="row">
                      <div>{card.data_grp_assigned}</div>
                      <div>
                        <Typography noWrap={true} sx={{ color: 'text.secondary', mb: 0.5, ml: 0.5 }}>{card.title}</Typography>
                      </div>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>}
        <Grid size={4} spacing={2}>
          {/*<Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Created by me
          </Typography>*/}
          <Demo>
            <List dense={dense}>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="go" onClick={() => handleClick(srList[0].link+"?filter=my_inc")}>
                    <ArrowForwardIosOutlined />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <SupportAgentOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText><span style={{ padding: "0.4em" }}>My Incidents</span><Chip sx={{ padding: "0.2em" }} label={srList[0].data_active} variant="outlined" /></ListItemText>
              </ListItem>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="go" >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <PeopleAltOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText><span style={{ padding: "0.4em" }}>My HR Cases</span><Chip sx={{ padding: "0.2em" }} label={srList[2].data_active} variant="outlined" /></ListItemText>
              </ListItem>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="go" >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <AssignmentOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText><span style={{ padding: "0.4em" }}>My Service Requests</span><Chip sx={{ padding: "0.2em" }} label={srList[1].data_active} variant="outlined" /></ListItemText>
              </ListItem>
              {(userRole === 'itil' || userRole === 'super_admin') && <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="go" >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <TaskOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText><span style={{ padding: "0.4em" }}>My Tasks</span><Chip sx={{ padding: "0.2em" }} label={srList[4].data_active} variant="outlined" /></ListItemText>
              </ListItem>}
              {(userRole === 'itil' || userRole === 'super_admin' || userRole === 'approver') && <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="go" >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <TaskOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText><span style={{ padding: "0.4em" }}>My Pending Approvals</span><Chip sx={{ padding: "0.2em" }} label={srList[3].data_active} variant="outlined" /></ListItemText>
              </ListItem>}
            </List>
          </Demo>
        </Grid>
      </Stack>
      <br />
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Get better everyday in your career!
      </Typography>
      <Grid
        container
        columns={12}
        spacing={2}
        sx={{ mb: (theme) => theme.spacing(2) }}>
        {career.map((card, index) => (
          <Grid size={{ xs: 10, sm: 8, lg: 4 }}>
            <Card >
              <CardContent>
                <Stack direction="row">
                  <div style={{ width: '30%', textAlignLast: 'center' }}><AccessTimeOutlined /><p style={{ margin: '0em' }}>15 mins</p></div>
                  <div style={{ width: '70%' }}>
                    <p style={{ margin: '0em 0em 0em 0.2em' }}>{card.title}</p>
                    <Chip label={card.app} color="primary" variant="outlined" />
                  </div>
                </Stack>
              </CardContent>
              <CardActions sx={{ justifyContent: 'right' }}>
                <IconButton size="small" edge="end" aria-label="thumbsup" sx={{ border: 'none', margin: '0px !important' }}><ThumbUpOffAltOutlined /></IconButton>
                <IconButton size="small" edge="end" aria-label="thumbsdown" sx={{ border: 'none', margin: '0px !important' }}><ThumbDownOffAltOutlined /></IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Upcoming Events
      </Typography>
      <Grid container spacing={2} columns={12} >
        <Grid size={{ md: 12, lg: 12 }}>
          <Card sx={{ backgroundColor: 'white' }}>
            <CardContent>
              <Typography
                id="ellipsis-list-demo"
                level="body-xs"
                sx={{ textTransform: 'uppercase', letterSpacing: '0.15rem', mb: '0.5rem' }}
              >
                Announcements
              </Typography>
              <Divider sx={{ mb: '1rem' }} />

              <Grid
                container
                spacing={2}
                columns={12}>
                {announcements.map((card, index) => (
                  <Grid key={index} size={{ xs: 10, sm: 8, lg: 4 }}>
                    <Card sx={{ borderRadius: '0px' }}>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          {card.title}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{card.content}</Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}


/*
<Grid size={{ xs: 6, sm: 4, lg: 2 }}>
          <HighlightedCard />
        </Grid>

<Grid size={{ sm: 12, md: 6 }}>
          <SessionsChart />
        </Grid>
        <Grid size={{ sm: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>

<Grid container spacing={2} columns={12}>
        <Grid size={{ md: 12, lg: 9 }}>
          <CustomizedDataGrid />
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
            <CustomizedTreeView />
            <ChartUserByCountry />
          </Stack>
        </Grid>
      </Grid>
*/


/*
overview design backup

<Grid size={8} spacing={2}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Overview
          </Typography>
          <Grid
            container
            spacing={2}
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}
          >
            {srList.map((card, index) => (
              <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
                <StatCard {...card} />
              </Grid>
            ))}
          </Grid>
        </Grid>


*/


/*
Events


<Typography
            id="ellipsis-list-demo"
            level="body-xs"
            sx={{ textTransform: 'uppercase', letterSpacing: '0.15rem' }}
          >
            Events
          </Typography>
          <List
            aria-labelledby="ellipsis-list-demo"
            sx={{ '--ListItemDecorator-size': '56px' }}
          >
            {event.map((card, index) => (
              <ListItem>
                <ListItemText primary={card.title} secondary={card.content} />
              </ListItem>
            ))}
          </List>
*/