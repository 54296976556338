import { OpenInNew } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import parse from 'html-react-parser';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
const ResourceCardMsg = (props) => {
    const [open, setOpen] = useState(false);
    let parsedContent = null;
    if (props.kb) {
        parsedContent = parse(props.kb.text);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div style={{ display: "inline" }}>
            {props.kb && <div style={{ display: "inline" }}>
                <Button variant="outlined" endIcon={<OpenInNew />} sx={{ margin: "5px" }} onClick={handleClickOpen}>
                    {props.kb.number} {/* Render lines up to the current line */}
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {props.kb.number} - {props.kb.short_description}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {parsedContent}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>}

            {props.inc && <div style={{ display: "inline" }}>
                <Button variant="outlined" endIcon={<OpenInNew />} sx={{ margin: "5px" }} onClick={handleClickOpen}>
                    {props.inc.number} {/* Render lines up to the current line */}
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {props.inc.number} - {props.inc.short_description}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p><b>Description: </b>
                                {props.inc.description}</p>
                            <p><b>Resolution Notes: </b>{props.inc.close_notes}</p>
                            {props.inc.workNotes.length > 0 && <p><b>Work Notes: </b></p>}
                            {props.inc.workNotes.length > 0 &&
                                <Timeline
                                    sx={{
                                        [`& .${timelineOppositeContentClasses.root}`]: {
                                            flex: 0.2,
                                        },
                                    }}
                                >
                                    {props.inc.workNotes.map((note, index) => (
                                        <TimelineItem key={index}>
                                            <TimelineOppositeContent color="textSecondary">
                                                {note.date}
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>{note.text}</TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>}
        </div>
    );

};

export default ResourceCardMsg;