import ChatWidget from "./ChatWidget";
import PrimarySearchAppBar from "./PrimarySearchAppBar";
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Divider } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { FormContainer, TextFieldElement, TextareaAutosizeElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { LoadingButton } from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import PropTypes from 'prop-types';
import { Select, selectClasses } from '@mui/base/Select';
import { Option, optionClasses } from '@mui/base/Option';
import { useTheme } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { Block, Label } from "@mui/icons-material";
import { useForm } from "react-hook-form-mui";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

export default function MyServiceRequests() {
  const [srList, setSrList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inc, setInc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length - 1);

  const getSRList = async () => {
    try {
      setTableLoading(true);
      const token = localStorage.getItem("token");
      let user = localStorage.getItem("user").toLocaleLowerCase();
      user = user.substring(1, user.length - 1)
      axios.defaults.headers.get['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.get['Content-Type'] = 'application/json';
      let res = await axios.get("https://kogniv.com/sn/sr_requests?active=true");
      if (res.status === 200) {
        let data = [];
        if (userRole === 'employee' || userRole === 'approver') {
          for (const [i, row] of res.data.entries()) {
            row.id = i
            if (row.sys_created_by == user) {
              data.push(row)
            }
          }
        } else if (userRole === 'itil') {
          console.log("data in itil>>> ", res.data)
          for (const [i, row] of res.data.entries()) {
            row.id = i
          }
          data = res.data;
        }
        const SR = data;
        setSrList(SR);
        setTableLoading(false);

      } else {
        console.log(res.status);
        setTableLoading(false);

      }
    }
    catch (error) {
      console.log("error while fetching inc list:::", error)
      if (error.response.data.detail.includes("401")) {
        console.log("refresh token>>>");
      }
      setTableLoading(false);

    }
  };
  useEffect(() => {
    getSRList()
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70, hideable: true },
    { field: 'number', headerName: 'Request ID', width: 130 },
    { field: 'sys_created_on', headerName: 'Created Date', width: 200, sortable: true, hideable: true },
    { field: 'priority', headerName: 'Priority', width: 200, hideable: true },
    { field: 'urgency', headerName: 'Urgency', width: 100, hideable: true },
    { field: 'short_description', headerName: 'Short Description', sortable: false, width: 300, hideable: true },
    { field: 'due_date', headerName: 'Due Date', width: 200, sortable: true, hideable: true }
  ];

  const rows = srList;

  const data = {
    "rows": rows,
    "columns": columns,
    "rowLength": rows.length,
    "maxColumns": 50
  }

  const paginationModel = { page: 0, pageSize: 10 };

  const handleRowClick = (params) => {
    console.log('clicked::: ', params)
    setVisible(true);
    let tempworkNotes = [];
    if (params.row.comments_and_work_notes === "") {
      params.row.workNotes = [];
    } else {
      params.row.comments_and_work_notes = params.row.comments_and_work_notes.replace('\n\n', '\n');
      tempworkNotes = params.row.comments_and_work_notes.split('\n');
      let workNotes = [];
      let note = {}
      tempworkNotes = tempworkNotes.filter(e => e !== '');
      for (const [i, row] of tempworkNotes.entries()) {

        if (i % 2 === 0) {
          note = {}
          note.date = row;
        } else {
          note.text = row;
          workNotes.push(note);
        }
      }
      console.log("workNotes:: ", workNotes)
      params.row.workNotes = workNotes;
    }
    if (params.row.assigned_to != null) {
      params.row.assigned_to_val = params.row.assigned_to.display_value;
    } else {
      params.row.assigned_to_val = null;
    }
    setInc(params.row);
    console.log("inc::::  ",);
  };

  const backToList = () => {
    setVisible(false);
    getSRList();
  }

  const handleFormSubmit = async () => {
    setLoading(true);
    const comments = document.getElementById('new_notes').value;
    console.log("comments::: ", comments)
    const payload = {
      "sys_id": inc.sys_id,
      "entity_type": "servicerequests",
      "comment": comments
    }
    console.log("payload>>>>", payload);
    try {
      const token = localStorage.getItem("token");
      axios.defaults.headers.patch['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
      axios.defaults.headers.patch['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.patch['Content-Type'] = 'application/json';

      let res = await axios.patch(`https://kogniv.com/sn/worknotes`, payload);
      console.log("response>>>>", res)
      setLoading(false);
      if (res.status === 200) {
        return (<Alert severity="success">Saved successfully!</Alert>);
      } {
        return (<Alert severity="error">Error while updating the notes. Try again.</Alert>);
      }

    } catch (error) {
      console.log("error while saving the record");
      setLoading(false);
      return (<Alert severity="error">Error while updating the notes. Try again.</Alert>);
    }
  };

  return (
    <div>
      <header>
        <PrimarySearchAppBar></PrimarySearchAppBar>
      </header>
      <main>
        {!visible && (<Paper elevation={2} sx={{ width: '60%', padding: '2%', display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}>
          <h3>My Service Requests</h3>
          <DataGrid
            {...data}
            loading={tableLoading}
            slotProps={{
              loadingOverlay: {
                noRowsVariant: 'linear-progress',
                variant: 'linear-progress'
              },
            }}
            initialState={{
              pagination: { paginationModel },
              pinnedColumns: {
                left: ['number'],
              }
            }}
            columnVisibilityModel={{
              // Hide columns status and traderName, the other columns will remain visible
              id: false
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{ border: 0 }}
            onRowClick={handleRowClick}
          />
        </Paper>)}

        {visible && (<Paper elevation={2} sx={{ width: '60%', padding: '2%', display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}>
          <Box>
            <Button onClick={backToList} ><ArrowBackIosOutlinedIcon sx={{ color: '#53B146' }}></ArrowBackIosOutlinedIcon></Button>
            <h3 style={{ display: 'inline' }}>{inc.number}</h3>
          </Box>
          <Divider sx={{ paddingTop: '2%', minWidth: '100%', marginBottom: '2%' }} variant="fullWidth" />
          <FormContainer defaultValues={inc} onSuccess={handleFormSubmit}
          >
            <Grid container spacing={2}>

              <TextFieldElement name="sys_created_on" label="Opened" value={inc.sys_created_on} disabled />
              <TextFieldElement name="assigned_to_val" label="Assigned To" value={inc.assigned_to_val} disabled />

              <TextFieldElement name="urgency" label="Urgency" value={inc.urgency} disabled />
              <TextFieldElement name="state" label="State" value={inc.state} disabled />
              <TextFieldElement name="short_description" label="Short Description" value={inc.short_description} fullWidth={true} />
              <TextareaAutosizeElement name="description" label="Description" value={inc.description} fullWidth={true} />
              <br />
              <h4>Work Notes</h4>
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                  },
                }}
              >
                {inc.workNotes.map((note) => (
                  <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                      {note.date}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{note.text}</TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
              <TextareaAutosizeElement id="new_notes" name="new_notes" label="Add Notes" placeholder="Add your notes here" fullWidth={true} />
              <Stack spacing={2} direction="row" sx={{ float: 'right' }}>
                <LoadingButton
                  sx={{ color: '#53B146', borderColor: '#53B146' }}
                  onClick={handleFormSubmit}
                  loading={loading}
                  loadingPosition="start"
                  variant="outlined"
                >
                  Save
                </LoadingButton>
                <Button variant="outlined" sx={{ color: '#53B146', borderColor: '#53B146' }}>Cancel</Button>
              </Stack>
            </Grid>
          </FormContainer>
        </Paper>)}
        <ChatWidget />
      </main>
    </div>
  );
};

/*
<React.Fragment>
                <Select
                  className="CustomSelect"
                  slots={{
                    root: Button1,
                  }}
                  slotProps={{
                    listbox: { className: 'CustomSelect-listbox' },
                    popup: { className: 'CustomSelect-popup' },
                  }} name="state" label="State" defaultValue={inc.state}>
                  <Option className="CustomSelect-option" value="New">New</Option>
                  <Option className="CustomSelect-option" value="In Progress">In Progress</Option>
                  <Option className="CustomSelect-option" value="Pending">Pending</Option>
                  <Option className="CustomSelect-option" value="Resolved">Resolved</Option>
                  <Option className="CustomSelect-option" value="Closed">Closed</Option>
                </Select>
                <Styles/>
              </React.Fragment>

*/

const cyan = {
  50: '#E9F8FC',
  100: '#BDEBF4',
  200: '#99D8E5',
  300: '#66BACC',
  400: '#1F94AD',
  500: '#0D5463',
  600: '#094855',
  700: '#063C47',
  800: '#043039',
  900: '#022127',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Button1 = React.forwardRef(function Button1(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

Button1.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
}

function Styles() {
  // Replace this with your app logic for determining dark mode
  const isDarkMode = useIsDarkMode();

  return (
    <style>
      {`
      .CustomSelect {
        position: relative;
        font-size: 0.875rem;
        box-sizing: border-box;
        min-width: 230px;
        padding: 8px 12px;
        border-radius: 8px;
        text-align: left;
        line-height: 1.5;
        background: ${isDarkMode ? grey[900] : '#fff'};
        border: 1px solid ${isDarkMode ? grey[700] : grey[200]};
        color: ${isDarkMode ? grey[300] : grey[900]};
        box-shadow: 0px 2px 4px ${isDarkMode ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;

        &:hover {
          background: ${isDarkMode ? grey[800] : grey[50]};
          border-color: ${isDarkMode ? grey[600] : grey[300]};
        }

        &.${selectClasses.focusVisible} {
          outline: 0;
          border-color: ${cyan[400]};
          box-shadow: 0 0 0 3px ${isDarkMode ? cyan[600] : cyan[200]};
        }

        & > svg {
          font-size: 1rem;
          position: absolute;
          height: 100%;
          top: 0;
          right: 10px;
        }
      }
      .CustomSelect-listbox {
        font-size: 0.875rem;
        box-sizing: border-box;
        padding: 6px;
        margin: 12px 0;
        min-width: 320px;
        border-radius: 12px;
        overflow: auto;
        outline: 0;
        background: ${isDarkMode ? grey[900] : '#fff'};
        border: 1px solid ${isDarkMode ? grey[700] : grey[200]};
        color: ${isDarkMode ? grey[300] : grey[900]};
        box-shadow: 0px 4px 6px ${isDarkMode ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
        };
      }
      .CustomSelect-popup {
        z-index: 1;
      }
      .CustomSelect-option {
        list-style: none;
        padding: 8px;
        border-radius: 8px;
        cursor: default;

        &:last-of-type {
          border-bottom: none;
        }

        &.${optionClasses.selected} {
          background-color: ${isDarkMode ? cyan[700] : cyan[100]};
          color: ${isDarkMode ? cyan[50] : cyan[900]};
        }

        &.${optionClasses.highlighted} {
          background-color: ${isDarkMode ? grey[800] : grey[100]};
          color: ${isDarkMode ? grey[300] : grey[900]};
        }

        &.${optionClasses.highlighted}.${optionClasses.selected} {
          background-color: ${isDarkMode ? cyan[700] : cyan[100]};
          color: ${isDarkMode ? cyan[50] : cyan[900]};
        }

        &:focus-visible {
          outline: 3px solid ${isDarkMode ? cyan[400] : cyan[300]};
        }

        &.${optionClasses.disabled} {
          color: ${isDarkMode ? grey[700] : grey[400]};
        }

        &:hover:not(.${optionClasses.disabled}) {
          background-color: ${isDarkMode ? grey[800] : grey[100]};
          color: ${isDarkMode ? grey[300] : grey[900]};
        }
      }
      `}
    </style>
  );
}