import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: 'https://api.kogniv.com', // Your API base URL
    headers: {
    },
});

// Function to get the access token from localStorage or another store
const getAccessToken = () => {
    let token = localStorage.getItem('token')
    if (token.includes("\"")) {
        token = token.substring(1, token.length - 1)
    }
    console.log("token:::", token)
    return token;
};

// Function to refresh the token
const refreshAccessToken = async () => {
    try {
        let refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken.includes("\"")) {
            refreshToken = refreshToken.substring(1, refreshToken.length - 1)
        }
        const response = await axios.get('https://api.kogniv.com/api/v1/auth/token/refresh?refresh_token='+refreshToken);
        const newAccessToken = response.data.access_token;
        localStorage.setItem('token', newAccessToken);
        return newAccessToken;
    } catch (error) {
        console.error('Token refresh failed:', error);
        throw error;
    }
};

// Axios request interceptor to add token
axiosInstance.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (config.customHeaders) {
            // Merge custom headers with default headers
            //console.log("config::::",config)
            config.headers = { ...config.headers, ...config.customHeaders };
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Axios response interceptor to handle 401 errors (Token Expiry)
axiosInstance.interceptors.response.use(
    (response) => response, // Return the response if it's successful
    async (error) => {
        const originalRequest = error.config;
        const status = error.response ? error.response.status : null;

        // Check for 401 Unauthorized and handle token expiration
        if (status === 401 && !originalRequest._retry) {
            console.log("request unauthorised")
            originalRequest._retry = true; // Prevent infinite retry loop

            try {
                console.log("try refreshtoken")
                const newAccessToken = await refreshAccessToken(); // Get new access token
                // Update the original request with the new token and retry the request
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosInstance(originalRequest); // Retry the original request
            } catch (refreshError) {
                console.error('Error refreshing token:', refreshError);
                // Optionally, you can redirect to login or handle logout
                window.location.href = '/'; // Redirect to login page
                return Promise.reject(refreshError);
            }
        }

        // Handle other errors
        return Promise.reject(error);
    }
);

export default axiosInstance;
