import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

// Typing animation for each text element
const TypingAnimation = ({ text, speed, onComplete }) => {
    const [typedText, setTypedText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timeout = setTimeout(() => {
                setTypedText((prev) => prev + text[index]);
                setIndex((prev) => prev + 1);
            }, speed);
            return () => clearTimeout(timeout);
        } else {
            onComplete(); // Trigger onComplete when typing finishes
        }
    }, [index, text, speed, onComplete]);

    return <span>{typedText}</span>;
};

const CustomResolutionMessage = (props, speed = 50) => {

    //let msg = props.state.messages[0].message.message.replace('```html', '');
    //let content = msg.replace('```', "");
    const content = `
    <h2>Welcome to the chat!</h2>
    <p>This is a bot typing a message.</p>
    <ol>
      <li>First item in the list</li>
      <li>Second item in the list</li>
      <li>Third item in the list</li>
    </ol>
    <h2>Enjoy your experience!</h2>
  `;
    // Parse the HTML content into React elements
    const parsedContent = parse(content);

    // State for current message index and animation complete tracking
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationComplete, setAnimationComplete] = useState(false);

    // Log parsed content only once on initial render
    useEffect(() => {
        console.log('Parsed Content:', parsedContent);
    }, []); // Empty dependency array ensures this runs only once on mount

    // Handle animation complete callback
    const handleAnimationComplete = () => {
        // If there are more messages, transition to the next one
        if (currentIndex < parsedContent.length - 1) {
            setTimeout(() => {
                setCurrentIndex((prevIndex) => prevIndex + 1); // Move to next message
                setAnimationComplete(false); // Reset for next message typing
            }, 1000); // Delay to simulate "waiting" before next message
        }
    };

    // Render parsed content with typing animation
    const renderContent = (node) => {
        if (typeof node === 'string') {
            return (
                <TypingAnimation
                    text={node}
                    speed={speed}
                    onComplete={handleAnimationComplete}
                />
            );
        }

        // If it's a React component (HTML element), recursively render it normally
        const { type, props } = node;
        const { children } = props;

        if (children) {
            return React.createElement(
                type,
                props,
                React.Children.map(children, renderContent)
            );
        }

        return null;
    };

    return (
        <div>
            {parsedContent.length > 0 && currentIndex < parsedContent.length && (
                <div key={currentIndex}>
                    {renderContent(parsedContent[currentIndex])}
                </div>
            )}
        </div>
    );
};


export default CustomResolutionMessage;