import React, { useEffect } from 'react';
//import { useLocalStorageTokenHoc } from '../../hooks/useLocalStorageTokenHoc';
import axios from 'axios';
//import TypedAnimatedMessageSingle from '../ChatWidget/TypedAnimatedMessageSingle'
import parse from 'html-react-parser';
import { createClientMessage, createCustomMessage } from 'react-chatbot-kit';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from './../../axios';
import keyword_extractor from 'keyword-extractor';

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'
const ActionProvider = ({ createChatBotMessage, setState, children, msg, botName }) => {
  const addMessageToState = (message) => {
    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };

  const removeMessageToState = (message) => {
    setState((prev) => {
      // Remove Loading here
      const newPrevMsg = prev.messages.slice(0, -1)
      console.log("newPrevMsg", newPrevMsg)
      return { ...prev, messages: [...newPrevMsg, message], }
    })
  };

  const clearStateMessages = () => {
    setState(prev => ({
      ...prev,
      messages: []
    }))
  };

  useEffect(() => {
    if (botName === 'knowledgeAssist') {
      //clearStateMessages();
      console.log("knowledge assist in action provider>>>>", msg);
      let uuid = uuidv4();
      try {
        if (msg) {
          msg = msg.replace('```json', '')
          msg = msg.replace('```html', '')
          msg = msg.replace('```', "");
          msg = JSON.parse(msg);
          console.log("response::::: ", msg)

          let message = createCustomMessage([uuid, msg.msg], 'custom2', {
            "payload": { "uuid": uuid, "references": msg.references }, widget: "options", "loading": true
          });
          addMessageToState(message)
        }
      } catch (err) {
        let message = createCustomMessage([uuid, "Sorry, Unable to get related information"], 'custom2', {
          "payload": { "uuid": uuid, "references": msg.references }, "loading": true
        });
        addMessageToState(message)
      }
    }
  }, []);

  const handleSelectResponse = async (props) => {
    let msg = props.target.innerHTML;
    console.log("message typed", msg);
    let usrMsg = createClientMessage(msg);
    addMessageToState(usrMsg);
    if (msg === 'Related incidents') {
      axios.defaults.headers.post['x-api-key'] = `test`;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      let shortDesc = localStorage.getItem('shortDesc');
      let keywords = keyword_extractor.extract(shortDesc, {
        language: "english",
        remove_digits: true,
        return_changed_case: true,
        remove_duplicates: false
      });
      let query = ''
      for (const [i, word] of keywords.entries()) {
        if (i != 0) {
          query = query + '^ORshort_descriptionLIKE' + word
        } else {
          query = 'short_descriptionLIKE' + word
        }

      }

      let data = {
        "appID": 1,
        "params": {
          "data": null,
          "sysId": null,
          "entity": "incident",
          "methodType": "get",
          "query": {
            "sysparm_query": {
              "search": query,
              "state": "7"
            },
            "sysparm_fields": "number,short_description"
          }
        }
      }

      let res = await axios.post(`${hostName}`, data);
      if (res.status === 200) {
        let uuid = uuidv4();
        let refs = [];
        for (const [, ref] of res.data.data.result.entries()) {
          refs.push(ref.number)
        }
        let message = '';
        //refs = ['INC0000038','INC0000038','INC0000038','INC0000038','INC0000038']
        if (refs.length > 0) {
          message = createCustomMessage([uuid, "<p>Here are the related incidents to the issue:</p>"], 'custom2', { "payload": { "uuid": uuid, "references": refs }, widget: "options", "loading": true })
        } else {
          message = createCustomMessage([uuid, "<p>No related incidents found for this issue, how else can I help you?</p>"], 'custom2', { "payload": { "uuid": uuid, "references": [] }, "loading": true })
        }

        addMessageToState(message);
      }

    } else {
      callChatBotAPI(msg)
    }
  }

  const callServiceCatalogAPI = async (userMessage) => {
    try {
      if (botName === 'knowledgeAssist') {
        callChatBotAPI(userMessage)
      } else {
        axios.defaults.headers.post['x-api-key'] = `test`;
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';

        let data = {
          "appID": 1,
          "params": {
            "data": null,
            "sysId": null,
            "entity": "servicecatalog",
            "methodType": "get",
            "query": {
              "sysparm_text": userMessage,
              "sysparm_display_value":true
            }
          }
        }

        let res = await axios.post(`${hostName}`, data);
        if (res.status === 200 && res.data && res.data.data && res.data.data.result && res.data.data.result.length > 0) {
          let uuid = uuidv4();
          let refs = [];
          
          for (const [, ref] of res.data.data.result.entries()) {
            if (ref.price && ref.price!="") {
              refs.push({name: ref.name, price: ref.price, category: ref.category.title, desc: ref.short_description})
            }
          }
          let message = createCustomMessage([uuid, ""], 'custom4', { "payload": { "uuid": uuid, "references": refs }, "loading": true })
          addMessageToState(message)
        } else {
          callChatBotAPI(userMessage)
        }
      }
    } catch (err) {
      console.log("error in bot", err)
      let uuid = uuidv4();
      let message = createCustomMessage([uuid, "Sorry, Unable to get related information"], 'custom2', {
        "payload": { "uuid": uuid, "references": msg.references }, "loading": true
      });
      addMessageToState(message);
    }
  }

  const callChatBotAPI = async (userMessage) => {
    try {
      const token = localStorage.getItem("token");
      console.log("message:::::::",)
      //create a loader message
      //let dummyUUID = uuidv4();
      //let dummyMsg = createCustomMessage([dummyUUID, 'Loading...'], 'custom3', { "payload": dummyUUID })
      //addMessageToState(dummyMsg);


      let message = "";
      let url = '';
      let customHeaders = {};
      customHeaders['Authorization'] = 'Bearer ' + token.substring(1, token.length - 1);
      customHeaders['Access-Control-Allow-Origin'] = '*';
      if (botName === 'knowledgeAssist') {
        url = 'https://api.kogniv.com/api/v1/chat/agent-assist';
        customHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
      } else {
        url = 'https://api.kogniv.com/api/v1/chat/employee-assist';
        customHeaders['Content-Type'] = 'application/json';
      }

      let threadID = null;
      let payload = {
        "message": userMessage
      }
      if (botName === 'employeeAssist') {
        threadID = localStorage.getItem('ea_conv_id')
        if (threadID.includes("null")) {
          threadID = null
        }
        let assets = JSON.parse(localStorage.getItem('snowAssets'))
        payload.details = JSON.parse(assets).details;
        if (threadID) {
          payload.thread_id = threadID;
        }
      } else {
        threadID = localStorage.getItem('aa_conv_id')
        if (threadID.includes("null")) {
          threadID = null
        }
        if (threadID) {
          url = url + `?thread_id=${threadID}`
        }
      }

      let uuid = uuidv4();


      const response = await axiosInstance.post(url, payload, { customHeaders });
      console.log('Login successful:', response);
      if (response.status === 200) {
        if (threadID === null) {
          if (botName === 'employeeAssist') {
            localStorage.setItem('ea_conv_id', response.data.thread_id)
          } else {
            localStorage.setItem('aa_conv_id', response.data.thread_id)
          }
        }
        if (response.data.response === "") {
          message = createChatBotMessage(
            "How can I assist you today?"
          );
        } else {
          //if (botName === 'knowledgeAssist') {
          console.log("knowledge assist in action provider>>>>");
          let res = response.data.response;
          let content = '';

          res = res.replace('```json', '')
          res = res.replace('```html', '')
          res = res.replace('```', "");
          res = JSON.parse(res);
          //console.log("response::::: ", JSON.parse(res))
          content = res.msg;
          //removeMessageToState(dummyMsg);
          //addMessageToState(createChatBotMessage(parsedContent, 'custom2'))
          message = createCustomMessage([uuid, content], 'custom2', { "payload": { "uuid": uuid, "references": res.references }, widget: "options", "loading": true })
        }
      } else {
        message = createChatBotMessage(
          "Sorry, I'm not able to help you with this. Could you ask something else please?"
        );
      }
      addMessageToState(message);
    } catch (err) {
      console.log("error in bot", err)
      let uuid = uuidv4();
      let message = createCustomMessage([uuid, "Sorry, Unable to get related information"], 'custom2', {
        "payload": { "uuid": uuid, "references": msg.references }, "loading": true
      });
      addMessageToState(message);
    }
  }



  const greet = async () => {
    let botName = localStorage.getItem('botName');
    let entity = localStorage.getItem('entity');
    let entityType = localStorage.getItem('entityType')
    let message = null;
    if (botName) {
      if (botName === 'knowledgeAssist') {
        if (entity != null && entity != '') {
          createChatBotMessage(entity);
          /*const token = localStorage.getItem("token");
          axios.defaults.headers.post['Authorization'] = 'Bearer ' + token.substring(1, token.length - 1);
          axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
          axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
          let inputString = entity
          const response = await axios.post('https://api.kogniv.com/api/v1/chat/session', {
            "message": inputString
          });
          console.log('Login successful:', response);
          if (response.status === 200) {
            if (response.data.result === "") {
              message = this.createChatBotMessage(
                "How can I assist you today?"
              );
            } else {
              message = this.createChatBotMessage(
                response.data.result
              );
            }
          } else {
            message = this.createChatBotMessage(
              "How can I assist you today?"
            );
          }
          */

        } else {
          console.log("entity::::", entity)
        }

      } else if (botName === 'employeeAssist') {
        message = createChatBotMessage("Hello, how may I assist you?");
      }
    }

    addMessageToState(message);
  };


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            callChatBotAPI,
            callServiceCatalogAPI,
            greet,
            handleSelectResponse
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
