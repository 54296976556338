import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert } from '@mui/material';

export default function UserVerification() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        completeVerification()
      }, [])
    //completeVerification();
    async function completeVerification() {
        setLoading(true);
        let token = searchParams.get("token");
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        try {
            let res = await axios.get(`https://kogniv-hadqfge7etfkbwfy.centralus-01.azurewebsites.net/api/v1/auth/register?token=${token}`);
            setLoading(false)
            if (res.status === 200) {
                navigate("/");
            } else {
                console.log(res);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {loading &&
                <div style={{ top: '50%', left: '50%', transform: 'translate(200%, 500%)'}}>
                    <CircularProgress />
                    <span>&nbsp; Verifying your email ID, please wait...</span>
                </div>
            }
            {!loading &&
                <div style={{ top: '50%', left: '50%', transform: 'translate(200%, 500%)'}}>
                    <Alert variant="outlined" severity="error">
                        Error while verifying, please try again!
                    </Alert>
                </div>
            }
        </Box>
    );
}
