import React from "react";
import { createCustomMessage } from "react-chatbot-kit";
import Options from "./options/Options";
import Quiz from "./quiz/Quiz";
import CustomResolutionMessage from "../ChatWidget/CustomResolutionMessage";
import TypedAnimatedMessageSingle from "../ChatWidget/TypedAnimatedMessageSingle";
import TypingAnimatedMessage from "../ChatWidget/TypingAnimatedMessage";
import CustomLoadingMessage from "../ChatWidget/CustomLoadingMessage";
import DynamicOptionsWidget from "../ChatWidget/DynamicOptionsWidget";

const config = {
  botName: "IT Assist",
  initialMessages: [{
    delay: undefined,
    id: null,
    loading: false,
    message: "Hello, how may I assist you???",
    type: "bot"
  },
  createCustomMessage('Test', 'custom'),
  createCustomMessage('Test', 'custom2'),
  ],
  customMessages: {
    custom: (props) => <CustomResolutionMessage {...props} />,
    custom2: (props) => <TypingAnimatedMessage {...props}/>,
    custom3: (props) => <CustomLoadingMessage {...props}/>,
    custom4: (props) => <DynamicOptionsWidget {...props}/>
  },
  customComponents: {},
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "javascriptQuiz",
      widgetFunc: (props) => <Quiz {...props} />,
      props: {
        questions: [
          {
            question: "What is closure?",
            answer:
              "Closure is a way for a function to retain access to it's enclosing function scope after the execution of that function is finished.",
            id: 1,
          },
          {
            question: "Explain prototypal inheritance",
            answer:
              "Prototypal inheritance is a link between an object and an object store that holds shared properties. If a property is not found on the host object, javascript will check the prototype object.",
            id: 2,
          },
        ],
      },
    },
  ],
};

export default config;
