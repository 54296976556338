import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import PrimarySearchAppBar from '../components/PrimarySearchAppBar';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import axios from "axios";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

function generate(element) {
    return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));


const UserManagement = () => {
    const [dense, setDense] = useState(false);
    const [secondary, setSecondary] = useState(false);
    const [openAddPeople, setOpenAddPeople] = useState(false);
    const [role, setRole] = useState('ITIL');
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertType, setAlertType] = useState('success');
    const handleOpenAddPeople = () => {
        setOpenAddPeople(true);
    };

    const handleSelectChange = (event) => {
        setRole(event.target.value);
    };

    const handleCloseAddPeople = () => {
        setOpenAddPeople(false);
    };

    const submitNewUserAdd = async (form) => {

        let payload = {};
        payload.email = form.new_user_email;
        payload.first_name = form.first_name;
        payload.last_name = form.last_name;
        payload.role = role;
        handleCloseAddPeople();
        const token = localStorage.getItem("token");
        axios.defaults.headers.post['Authorization'] = `Bearer ${token.substring(1, token.length - 1)}`;
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        let res = await axios.post(`https://api.kogniv.com/api/v1/auth/register/user`, payload);
        if (res.status === 201) {
            setAlertContent(res.data.message);
            console.log(res)
            setAlert(true);
            setAlertType("success");
        } else {
            setAlertContent("Error while inviting the user, please try again.");
            setAlert(true);
            setAlertType("error");
        }

    }

    return (
        <div>
            <header>
                <PrimarySearchAppBar></PrimarySearchAppBar>
            </header>
            <main>
                <Box sx={{ flexGrow: 1, margin: '1%' }}>
                    <Grid container spacing={2}>
                        <Grid size={8}>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mt: 4, mb: 2, display: 'inline-block' }} variant="h6" component="div">
                                    Users
                                </Typography>
                                <Button sx={{ float: 'right', padding: '11px', marginLeft: '11px' }} variant="outlined" onClick={handleOpenAddPeople} >Add people</Button>

                                <Demo>
                                    <List dense={dense}>
                                        {generate(
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            >

                                                <ListItemAvatar>
                                                    <Avatar {...stringAvatar('John Smith')} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="John Smith"
                                                    secondary={secondary ? 'Secondary text' : null}
                                                />
                                                <ListItemButton sx={{ width: "150px", justifyContent: 'right', paddingRight: '10px' }}>
                                                    <FormControl sx={{ width: "150px" }}>
                                                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value="ITIL"
                                                            label="Role"
                                                        >
                                                            <MenuItem value="ITIL">ITIL</MenuItem>
                                                            <MenuItem value="Approver">Approver</MenuItem>
                                                            <MenuItem value="Employee">Employee</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemButton>
                                            </ListItem>,
                                        )}
                                    </List>
                                </Demo>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={openAddPeople}
                    onClose={handleCloseAddPeople}
                    fullWidth
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            console.log(event)
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            submitNewUserAdd(formJson)
                        },
                    }}
                >
                    <DialogTitle>Add a new person</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter the email ID of the user
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="new_user_email"
                            name="new_user_email"
                            label="Email ID"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            type="text"

                        />
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            type="text"

                        />
                        <FormControl sx={{ width: "150px" }}>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                labelId="role"
                                id="role"
                                value={role}
                                label="Role"
                                onChange={handleSelectChange}
                                required
                            >
                                <MenuItem value="ITIL">ITIL</MenuItem>
                                <MenuItem value="Approver">Approver</MenuItem>
                                <MenuItem value="Employee">Employee</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddPeople}>Cancel</Button>
                        <Button type="submit">Create</Button>
                    </DialogActions>
                </Dialog>
                {alert ? <Alert severity={alertType}>{alertContent}</Alert> : <></>}
            </main>
        </div>
    );
}

export default UserManagement;