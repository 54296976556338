import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function TemporaryDrawer() {
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState('Dashboard')
  const navigate = useNavigate();
  let menu = [];
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  let userRole = localStorage.getItem("role").toLocaleLowerCase();
  userRole = userRole.substring(1, userRole.length-1);
  if (userRole === "employee") {
    menu = ['Dashboard', 'My Incidents', 'My Service Requests', 'My HR Cases'];
  } else if (userRole === "itil" ) {
    menu = ['Dashboard', 'Incidents', 'Service Requests', 'HR Cases', 'Tasks'];
  } else if (userRole === "super_admin") {
    menu = ['Dashboard', 'Incidents', 'Service Requests', 'HR Cases', 'Tasks', 'Users Management'];
  } else if (userRole === "approver") {
    menu = ['Dashboard', 'My Incidents', 'My Service Requests', 'My HR Cases', 'My Approvals'];
  }

  const navigateToPage = (page) => () => {
    console.log("opened page " + page);
    setComponent(page);
    if (page === "My Incidents" || page === "Incidents") {
        navigate("/my-incidents");
    } else if (page === "My HR Cases" || page === "HR Cases") {
        navigate("/my-cases");
    } else if (page === "My Service Requests" || page === "Service Requests") {
      navigate("/my-sr");
    } else if (page === "My Tasks" || page === "Tasks") {
      navigate("/my-tasks");
    }
    else if (page === "My Approvals" || page === "Approvals") {
      navigate("/my-approvals");
    } else if (page === "Dashboard") {
        navigate("/home");
    } else if (page === "Users Management") {
      navigate("/users-management");
  }
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {menu.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} onClick={navigateToPage(text)}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Knowledge Article', 'FAQs'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 , color:'#53B146'}} onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
