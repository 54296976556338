import React, { useEffect, useState } from 'react';
import Typed from 'typed.js';
import Scroll from 'react-scroll';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import ResourceCardMsg from './resourceCardMsg';
const ResourceCardMsgMemo = React.memo(ResourceCardMsg)

const hostName = 'https://kogniv-iservice-gvgmhpeuf5eaaghu.centralus-01.azurewebsites.net/api/main';
//const hostName = 'http://localhost:8000/api/main'
const TypingAnimatedMessage = (props) => {
  const [fetchedRefs, setFetchedRefs] = useState(false);
  const [refContent, setRefContent] = useState({ kb: [], inc: [] });
  let fetchedCount = 0;
  let message = '';
  let uuid = uuidv4() + 'msg';
  console.log("current message:::", props);

  let references = props.payload.references;
  //let references = ["KB0000002", "KB0000001"];

  const fetchRefs = async () => {
    if (references && references.length > 0) {
      for (const [i, ref] of references.entries()) {
        axios.defaults.headers.post['x-api-key'] = `test`;
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        let data = {
          "appID": 1,
          "params": {
            "data": null,
            "entity": "",
            "methodType": "get",
            "query": {
              "sysparm_query": {
                "number": ref
              }
            }
          }
        };
        if (ref.includes("KB")) {
          data.params.entity = "knowledge";
          data.params.query.sysparm_fields = "number,short_description,text,published"
        } else if (ref.includes("INC")) {
          data.params.entity = "incident"
          data.params.query.sysparm_fields = "number,short_description,description,comments_and_work_notes,close_notes"
          data.params.query.sysparm_display_value = "true"
        }
        try {
          let res = await axios.post(`${hostName}`, data);
          if (res.status === 200) {
            if (res.data.data.result.length > 0) {
              let refTemp = refContent;
              console.log("ref:::", res)
              if (ref.includes("KB")) {
                refTemp.kb.push(res.data.data.result[0]);
              } else if (ref.includes("INC")) {
                let inc = res.data.data.result[0];
                console.log("incident:: ", inc)
                
                inc.comments_and_work_notes = inc.comments_and_work_notes.replace('\n\n', '\n');
                let tempworkNotes = inc.comments_and_work_notes.split('\n');
                let workNotes = [];
                let note = {}
                tempworkNotes = tempworkNotes.filter(e => e !== '');
                for (const [i, row] of tempworkNotes.entries()) {

                  if (i % 2 === 0) {
                    note = {}
                    note.date = row;
                  } else {
                    note.text = row;
                    workNotes.push(note);
                  }
                }
                console.log("workNotes:: ", workNotes)
                inc.workNotes = workNotes;

                refTemp.inc.push(inc);
              }
              setFetchedRefs(refTemp);
            }
            fetchedCount++;
            if (fetchedCount === references.length) {
              setFetchedRefs(true);
            }
          }
        } catch (err) {
          console.log("error while fetching reference:: ", ref)
          console.log("Error stack::", err);
          fetchedCount++;
          if (fetchedCount === references.length) {
            setFetchedRefs(true);
          }
        }
      }


    }
  }
  if (props.state.messages && props.state.messages.length > 0) {
    for (const [, msg] of props.state.messages.entries()) {
      if (msg.type === 'custom2') {
        if ((props.payload.uuid === msg.payload.uuid)) {
          message = msg.message[1];
        }
      }
    }
  }

  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  useEffect(() => {
    fetchRefs();
    const typed = new Typed(el.current, {
      strings: [message],
      typeSpeed: 10,
      showCursor: false,
      onStringTyped: () => {
        console.log("done typing")
      },
    });

    // Set up MutationObserver to watch for changes
    const observer = new MutationObserver(() => {
      // Scroll the div to the bottom when content changes
      if (el.current) {
        //el.current.scrollTop = el.current.scrollHeight;
        props.scrollIntoView(({ behavior: "smooth", block: "end", }))
      }
    });

    // Options for MutationObserver to observe child elements and text changes
    const config = {
      childList: true,  // Observe additions/removals of child elements
      subtree: true,    // Observe the entire subtree of the div
      characterData: true, // Observe changes to text content
    };

    // Start observing the div element
    if (el.current) {
      observer.observe(el.current, config);
    }
    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
      if (observer && el.current) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div id={uuid} >
      <span ref={el} />
      {fetchRefs &&
        <div>
          {refContent.kb.map((card, index) => (
            <ResourceCardMsgMemo kb={card} inc={null}></ResourceCardMsgMemo>
          ))}
          {refContent.inc.map((card, index) => (
            <ResourceCardMsgMemo inc={card} kb={null}></ResourceCardMsgMemo>
          ))}
        </div>}
    </div>
  );
}

export default TypingAnimatedMessage;