import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';

import ForgotPassword from './ForgotPassword';
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';
import axios from 'axios';
import { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { LoadingButton } from '@mui/lab';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

export default function SignInCard() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
      organization: data.get('organization'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      domain: data.get('domain')
    });
  };

  const validateInputs = async () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');
    const organization = document.getElementById('organization');
    const firstName = document.getElementById('firstName');
    const lastName = document.getElementById('lastName');
    const domain = document.getElementById('domain');
    /*
    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
    */
    try {
      setLoading(true);
      const response = await axios.post('https://api.kogniv.com/api/v1/auth/register/admin', {
          "email": email.value,
          "password": password.value,
          "tenant_name" : organization.value,
          "domain" : domain.value,
          "first_name" : firstName.value,
          "last_name" : lastName.value
      });
      console.log('Sign up response:', response);
      setLoading(false);
      if (response.status === 200) {
        navigate("/");
      } else {
          alert("Invalid username or password");
      }
  } catch (error) {
    setLoading(false);
    if (error.status === 401) {
        alert("Invalid username or password");
        console.error('Login error:', error);
    } else {
        alert("Error, try after sometime");
        console.error('Login error:', error);
    }
      
  }
  };

  return (
    <Card variant="outlined">
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <SitemarkIcon />
      </Box>
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Sign Up
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="organization">Organization Name</FormLabel>
          <TextField
            id="organization"
            type="text"
            name="organization"
            placeholder="Your organization name"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? 'error' : 'primary'}
            sx={{ ariaLabel: 'organization' }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="domain">Domain</FormLabel>
          <TextField
            id="domain"
            type="text"
            name="domain"
            placeholder="yourdomain.com"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? 'error' : 'primary'}
            sx={{ ariaLabel: 'domain' }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="firstName">First Name</FormLabel>
          <TextField
            id="firstName"
            type="text"
            name="firstName"
            placeholder="First Name"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? 'error' : 'primary'}
            sx={{ ariaLabel: 'firstName' }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="lastName">Last Name</FormLabel>
          <TextField
            id="lastName"
            type="text"
            name="lastName"
            placeholder="Last Name"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? 'error' : 'primary'}
            sx={{ ariaLabel: 'lastName' }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            error={emailError}
            helperText={emailErrorMessage}
            id="email"
            type="email"
            name="email"
            placeholder="your@email.com"
            autoComplete="email"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? 'error' : 'primary'}
            sx={{ ariaLabel: 'email' }}
          />
        </FormControl>
        <FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel htmlFor="password">Password</FormLabel>
          </Box>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={passwordError ? 'error' : 'primary'}
          />
        </FormControl>
        <ForgotPassword open={open} handleClose={handleClose} />
        <LoadingButton sx={{color:'white !important'}}
          type="submit" fullWidth variant="contained" onClick={validateInputs}
          loading={loading}
          loadingPosition="start"
        >Sign Up</LoadingButton>
        <Typography sx={{ textAlign: 'center' }}>
          Already signed up?{' '}
          <span>
            <Link
              href="/"
              variant="body2"
              sx={{ alignSelf: 'center' }}
            >
              Login
            </Link>
          </span>
        </Typography>
      </Box>
    </Card>
  );
}
