import React from 'react';
import ChatWidget from '../components/ChatWidget';
import PrimarySearchAppBar from '../components/PrimarySearchAppBar';
import Dashboard from '../components/Dashboard/Dashboard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';

const ChatWidgetWMemo = React.memo(ChatWidget)
const HomePage = () => {  
  localStorage.setItem('ea_conv_id', null)
  console.log("ea conv id in home page::: ",localStorage.getItem('ea_conv_id'))

  return (
    <div>
      <header>
        <PrimarySearchAppBar></PrimarySearchAppBar>
      </header>
      <main>
        <Box sx={{ flexGrow: 1, margin: '1%' }}>
          <Grid container spacing={2}>
            <Grid size={8}>
              <Paper elevation={2}><Dashboard /></Paper>
            </Grid>
            <Grid size={4} >
              <ChatWidgetWMemo botName='employeeAssist' entityType="" entity="" />
            </Grid>
          </Grid>
        </Box>
      </main>
    </div>
  );
}

export default HomePage;