import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { Secret } from "./pages/Secret";
import "./App.css";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./hooks/useAuth";
import SignInSide from "./pages/sign-in-side/SignInSide";
import SignUpSide from "./pages/sign-up-side/SignUpSide";
import MyIncidents from "./components/MyIncidents";
import MyCases from "./components/MyCases";
import MyServiceRequests from "./components/MyServiceRequests";
import MyApprovals from "./components/MyApprovals";
import MyTasks from "./components/MyTasks";
import UserVerification from "./pages/verification";
import UserManagement from "./pages/UserManagement";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/" element={<SignInSide />} />
        <Route path="/sign-up" element={<SignUpSide />} />
        <Route path="/my-cases" element={<MyCases />} />
        <Route path="/my-incidents" element={<MyIncidents />} />
        <Route path="/my-sr" element={<MyServiceRequests />} />
        <Route path="/my-approvals" element={<MyApprovals />} />
        <Route path="/my-tasks" element={<MyTasks />} />
        <Route path="/verify-user" element={<UserVerification />} />
        <Route path="/users-management" element={<UserManagement />} />
        <Route
          path="/secret"
          element={
            <ProtectedRoute>
              <Secret />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;